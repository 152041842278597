<template>
  <ion-page>
    <ion-content class="top-safe-space">
      <div class="content tablet-centered ion-padding-start ion-padding-end pt-5">
        <div>
          <div class="is-flex ion-align-items-center ion-justify-content-center is-flex-direction-column ion-margin-bottom">
            <h1 class="title fw-900 fz-30 ion-margin-bottom">Medicast</h1>
            <IonImg src="./assets/logo.png" />
          </div>

          <h1 class="ion-text-center fz-16 ion-margin-bottom">
            {{ $t('notFound.title') }}
          </h1>

          <!--          <p class="ion-text-center fz-14 is-flex ion-align-items-center ion-justify-content-center is-flex-direction-column">-->
          <!--            <a-->
          <!--              class="mb-3"-->
          <!--              href="#"-->
          <!--              @click.prevent="openInBrowser"-->
          <!--            >-->
          <!--              {{ $t('notFound.clickHere') }}-->
          <!--            </a>-->

          <!--            <ion-button-->
          <!--              fill="outline"-->
          <!--              class="copy rounded-btn bg-white"-->
          <!--              shape="round"-->
          <!--              mode="ios"-->
          <!--              @click="copy(baseUrl + $route.fullPath)"-->
          <!--            >-->
          <!--              <ion-icon-->
          <!--                :icon="copyOutline"-->
          <!--                mode="ios"-->
          <!--              />-->
          <!--            </ion-button>-->
          <!--          </p>-->
        </div>

        <div
          class="
            contact-us-container
            is-flex
            ion-justify-content-center ion-align-items-center
            is-flex-direction-column
          "
        >
          <div class="is-flex ion-justify-content-center w-100">
            <ion-button
              mode="ios"
              class="uppercase w-100"
              expand="block"
              href="/"
            >
              {{ $t('commonKeys.homePage') }}
            </ion-button>
          </div>

          <a
            class="contact-us mt-4"
            @click="$router.push('/contact')"
          >
            {{ $t('contact.us') }}
          </a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import useInAppBrowser from '@/composables/useInAppBrowser';
import useCopyToClipboard from '@/composables/useCopyToClipboard';
import useAppStorage from "@/composables/useAppStorage";
import { useRoute, useRouter } from 'vue-router';
import auth from "@/plugins/auth";

import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonImg,
} from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { onMounted, ref } from '@vue/runtime-core';
import {NOT_FOUNT_REDIRECT_STORAGE_KEY} from "@/config/constants";

export default {
  layout: 'mobile-default',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonImg,
  },

  setup() {
    const { openUrl } = useInAppBrowser();
    const { copy } = useCopyToClipboard();
    const { setKey } = useAppStorage();
    const route = useRoute();
    const router = useRouter();
    const baseUrl = process.env.VUE_APP_URL;

    const app = ref(null);

    const openInBrowser = () => {
      openUrl(`${process.env.VUE_APP_URL}${route.fullPath}`);
    };

    onMounted(() => {
      if (!auth.user()) {
        setKey(NOT_FOUNT_REDIRECT_STORAGE_KEY, route.fullPath);
        router.push('/login');
      }
    });

    return {
      copy,
      copyOutline,
      app,
      baseUrl,
      openInBrowser,
    };
  },

};
</script>

<style lang="scss" scoped>
.contact-us-container {
  padding: 0px 0 10px;
}

.contact-us {
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
}

.copy {
  font-size: 10px;
  padding: 0;
  height: 30px;
  width: 30px;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
}
</style>
